<template>
  <v-container grid-list-xl>
    <v-layout row wrap justify-space-between>
      <v-flex xs8>
        <view-title />
      </v-flex>
      <v-flex xs4 class="display-flex justify-end pt-4">
        <div class="FAB-Extended-PrimaryColor-Enabled">
          <v-btn type="submit" color="primary" @click="addNewAnnouncement">
            <v-icon left dark>mdi-plus</v-icon>NEW ANNOUNCEMENT
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :no-data-text="loading ? 'Please wait while data is loading' : 'No announcements found'"
          :headers="tableHeaders"
          :loading="loading"
          :items="announcementsProxy"
          :pagination.sync="pagination"
          class="data-table"
        >
          <template v-slot:items="props">
            <tr style="height: 50px !important; width: 950px !important" class="noselect clickable">
              <td style="width: 40px !important">{{ props.item.leftPaddingHack }}</td>
              <td style="width: 200px !important" @click="edit(props.item)">
                {{ props.item.name }}
              </td>
              <td @click="edit(props.item)">{{ props.item.type }}</td>
              <td @click="edit(props.item)">{{ props.item.app }}</td>
              <td style="text-decoration: underline" @click="openSitesDialog(props.item)">
                <a>{{ props.item.sitesCount }} Sites</a>
              </td>
              <td style="text-decoration: underline" @click="openImagesDialog(props.item)">
                <a>{{ props.item.imageText }}</a>
              </td>
              <td @click="edit(props.item)">
                <div :class="props.item.active ? 'active-box' : 'inactive-box'">
                  <v-layout justify-center>{{ props.item.status }}</v-layout>
                </div>
              </td>
              <td style="width: 40px !important">{{ props.item.rightPaddingHack }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="imagesDialog" width="600">
      <v-card v-if="selectedAnnouncement">
        <v-card-title class="H4-Secondary-Center">{{ selectedAnnouncement.name }}</v-card-title>
        <div class="v-card-content">
          <v-layout>
            <v-flex xs10>
              <p class="text-align-left">
                <b>English Version</b>
              </p>
            </v-flex>
            <v-flex xs2>
              <p xs4 class="text-align-right">
                <a flat @click="edit(selectedAnnouncement)">EDIT</a>
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <img
                :src="
                  getImageUrl(selectedAnnouncement, 'en') ||
                    require('@/assets/announcement-img-default.png')
                "
                :alt="getDescription(selectedAnnouncement, 'en')"
                width="560px"
                height="240px"
              />
            </v-flex>
          </v-layout>
          <v-layout class="mt-2">
            <v-flex xs10>
              <p class="text-align-left">
                <b>French Version</b>
              </p>
            </v-flex>
            <v-flex xs2>
              <p xs4 class="text-align-right">
                <a flat @click="edit(selectedAnnouncement)">EDIT</a>
              </p>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <img
                :src="
                  getImageUrl(selectedAnnouncement, 'fr') ||
                    require('@/assets/announcement-img-default.png')
                "
                :alt="getDescription(selectedAnnouncement, 'fr')"
                width="560px"
                height="240px"
              />
            </v-flex>
          </v-layout>
        </div>
        <v-card-actions style="padding: 15px">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="sitesDialog" width="600">
      <v-card v-if="selectedAnnouncement">
        <v-card-title class="H4-Secondary-Center">{{ selectedAnnouncement.name }}</v-card-title>
        <div class="v-card-content">
          <v-layout>
            <v-flex xs12>
              <b>Sites</b>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2">
            <v-flex xs12 style="height: 200px; overflow: scroll">
              <div
                v-for="(resource, rIndex) of getSites(selectedAnnouncement)"
                v-bind:key="rIndex"
                style="height: 25px"
              >
                <v-layout>{{ resource.name }}</v-layout>
              </div>
            </v-flex>
          </v-layout>
        </div>
        <v-card-actions style="padding: 15px">
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closeDialog">Cancel</v-btn>
          <v-btn color="primary" flat @click="edit(selectedAnnouncement)">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ID from '@compassdigital/id';

const IMAGE_LINK_TEXT = 'Click to view image';

export default {
  name: 'AnnoucementListing',
  data: () => ({
    tableHeaders: [
      { text: '', value: 'leftPaddingHack', sortable: false },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Type', value: 'type', sortable: false },
      { text: 'App', value: 'app', sortable: true },
      { text: 'Sites', value: 'sitesCount', sortable: false },
      { text: 'Image', value: 'imageText', sortable: false },
      { text: 'Status', value: 'status', sortable: true },
      { text: '', value: 'rightPaddingHack', sortable: false },
    ],
    pagination: {
      rowsPerPage: 10,
    },
    loading: false,
    announcementsProxy: [],
    imagesDialog: false,
    sitesDialog: false,
    selectedAnnouncement: null,
    multigroupMap: {},
  }),
  computed: {
    ...mapState('announcements', ['announcements']),
    ...mapState('sites', ['siteMap', 'multigroups']),
  },
  methods: {
    ...mapActions('announcements', ['fetchAnnouncements']),
    ...mapActions('sites', ['getLocationMultigroup']),
    addNewAnnouncement() {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Create New Announcement',
        to: { name: 'announcement-new' },
      });
      this.$router.push({
        name: 'announcement-new',
      });
    },
    getSites(a) {
      if (a.is_global) {
        const cdlMultiGroupID = this.getCDLMultiGroupID(a.key);
        const multigroup = this.multigroupMap[cdlMultiGroupID];
        return (
          multigroup &&
          multigroup.groups.map((s) => ({
            ...s,
          }))
        );
      }
      return (a.allowed_resources || []).map((s) => ({
        ...this.siteMap[s],
      }));
    },
    openSitesDialog(announcement) {
      this.sitesDialog = true;
      this.$set(this, 'selectedAnnouncement', {
        ...announcement,
        info: {
          en: {
            ...((announcement.info && announcement.info.en) || {}),
          },
          fr: {
            ...((announcement.info && announcement.info.fr) || {}),
          },
        },
      });
    },
    openImagesDialog(announcement) {
      this.imagesDialog = true;
      this.$set(this, 'selectedAnnouncement', announcement);
    },
    closeDialog() {
      this.sitesDialog = false;
      this.imagesDialog = false;
      this.$set(this, 'selectedAnnouncement', null);
    },
    edit(announcement) {
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 1,
        title: 'Edit Announcement',
        to: { name: 'announcement-settings', params: { announcement_id: announcement.id } },
      });
      this.$router.push({
        name: 'announcement-settings',
        params: {
          announcement_id: announcement.id,
        },
      });
    },
    getImageUrl(a, l) {
      return (a && a.info && a.info[l] && a.info[l].image_url) || null;
    },
    getDescription(a, l) {
      return (a && a.info && a.info[l] && a.info[l].description) || null;
    },
    getCDLMultiGroupID(key) {
      const multigroupName = ID(key) && ID(key).id;
      const selectedMultigroup = this.multigroups.filter(
        (m) => m.name.toLowerCase() === multigroupName.toLowerCase(),
      );
      return selectedMultigroup.length > 0 && selectedMultigroup[0].id;
    },
  },
  async mounted() {
    this.loading = true;
    this.$store.commit('adminPanel/setLoading', true);

    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Announcements',
      to: { name: 'announcement-listing' },
    });
    // get announcements
    try {
      const apps = this.multigroups
        .filter((a) => {
          return ['Rogers', 'JJKitchen', 'Thrive', 'Nourish', 'Boost'].includes(a.name);
        })
        .map((a) => ({
          text: a.name,
          value: a.id,
        }));
      const promises = apps.map(async (app) => {
        const multigroup = await this.getLocationMultigroup({ id: app.value });
        this.$set(this.multigroupMap, app.value, { name: app.text, groups: multigroup.groups });
      });
      promises.push(this.fetchAnnouncements());
      await Promise.all(promises);
      if (!this.announcements || !this.announcements.length) {
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: 'Create New Announcement',
          to: { name: 'announcement-new' },
        });
        this.$router.push({
          name: 'announcement-new',
        });
        return;
      }

      this.$set(
        this,
        'announcementsProxy',
        this.announcements.reduce((result, announcement) => {
          const cdlMultiGroupID = this.getCDLMultiGroupID(announcement.key);

          const sites = announcement.is_global
            ? this.multigroupMap[cdlMultiGroupID] && this.multigroupMap[cdlMultiGroupID].groups
            : announcement.allowed_resources;

          if (
            !announcement.is_global &&
            !announcement.allowed_resources.find((id) => this.siteMap[id])
          )
            return result;

          if (Object.keys(announcement).length !== 0)
            result.push({
              ...announcement,
              sitesCount: announcement.is_global ? 'All' : (sites && sites.length) || 0,
              imageText: IMAGE_LINK_TEXT,
              status: announcement.active ? 'Active' : 'In-active',
            });
          return result;
        }, []),
      );
    } catch (error) {
      console.error(error);
      this.$toast.error('Could not load announcements');
    }
    this.$store.commit('adminPanel/setLoading', false);
    this.loading = false;
  },
};
</script>

<style scoped>
.active-box {
  height: 23px;
  width: 88px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #0a43a7;
}

.inactive-box {
  height: 23px;
  width: 88px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.15px;
  color: #000000;
  border-radius: 4px;
  background-color: #fafafa;
}

table.v-table tbody td,
table.v-table tbody th {
  height: 80px !important;
}

.data-table >>> .v-datatable thead {
  background: #fafafa;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}

.data-table >>> .v-datatable thead tr:not(.v-datatable__progress) th:first-child,
.data-table >>> .v-datatable tbody tr td:first-child {
  padding: 28px 0px 28px 40px !important;
}

.data-table >>> .v-datatable thead th.column.sortable .v-icon {
  margin-left: 12px;
  vertical-align: middle;
}
</style>
