import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { attrs: { row: "", wrap: "", "justify-space-between": "" } },
        [
          _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
          _c(
            VFlex,
            {
              staticClass: "display-flex justify-end pt-4",
              attrs: { xs4: "" }
            },
            [
              _c(
                "div",
                { staticClass: "FAB-Extended-PrimaryColor-Enabled" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { type: "submit", color: "primary" },
                      on: { click: _vm.addNewAnnouncement }
                    },
                    [
                      _c(VIcon, { attrs: { left: "", dark: "" } }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("NEW ANNOUNCEMENT\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            VFlex,
            { attrs: { xs12: "" } },
            [
              _c(VDataTable, {
                staticClass: "data-table",
                attrs: {
                  "no-data-text": _vm.loading
                    ? "Please wait while data is loading"
                    : "No announcements found",
                  headers: _vm.tableHeaders,
                  loading: _vm.loading,
                  items: _vm.announcementsProxy,
                  pagination: _vm.pagination
                },
                on: {
                  "update:pagination": function($event) {
                    _vm.pagination = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "noselect clickable",
                            staticStyle: {
                              height: "50px !important",
                              width: "950px !important"
                            }
                          },
                          [
                            _c(
                              "td",
                              { staticStyle: { width: "40px !important" } },
                              [_vm._v(_vm._s(props.item.leftPaddingHack))]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: { width: "200px !important" },
                                on: {
                                  click: function($event) {
                                    return _vm.edit(props.item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(props.item.name) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.edit(props.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.type))]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.edit(props.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.app))]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: { "text-decoration": "underline" },
                                on: {
                                  click: function($event) {
                                    return _vm.openSitesDialog(props.item)
                                  }
                                }
                              },
                              [
                                _c("a", [
                                  _vm._v(
                                    _vm._s(props.item.sitesCount) + " Sites"
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: { "text-decoration": "underline" },
                                on: {
                                  click: function($event) {
                                    return _vm.openImagesDialog(props.item)
                                  }
                                }
                              },
                              [_c("a", [_vm._v(_vm._s(props.item.imageText))])]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.edit(props.item)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: props.item.active
                                      ? "active-box"
                                      : "inactive-box"
                                  },
                                  [
                                    _c(
                                      VLayout,
                                      { attrs: { "justify-center": "" } },
                                      [_vm._v(_vm._s(props.item.status))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticStyle: { width: "40px !important" } },
                              [_vm._v(_vm._s(props.item.rightPaddingHack))]
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.imagesDialog,
            callback: function($$v) {
              _vm.imagesDialog = $$v
            },
            expression: "imagesDialog"
          }
        },
        [
          _vm.selectedAnnouncement
            ? _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
                    _vm._v(_vm._s(_vm.selectedAnnouncement.name))
                  ]),
                  _c(
                    "div",
                    { staticClass: "v-card-content" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(VFlex, { attrs: { xs10: "" } }, [
                            _c("p", { staticClass: "text-align-left" }, [
                              _c("b", [_vm._v("English Version")])
                            ])
                          ]),
                          _c(VFlex, { attrs: { xs2: "" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "text-align-right",
                                attrs: { xs4: "" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { flat: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.edit(
                                          _vm.selectedAnnouncement
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("EDIT")]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        [
                          _c(VFlex, { attrs: { xs12: "" } }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.getImageUrl(
                                    _vm.selectedAnnouncement,
                                    "en"
                                  ) ||
                                  require("@/assets/announcement-img-default.png"),
                                alt: _vm.getDescription(
                                  _vm.selectedAnnouncement,
                                  "en"
                                ),
                                width: "560px",
                                height: "240px"
                              }
                            })
                          ])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mt-2" },
                        [
                          _c(VFlex, { attrs: { xs10: "" } }, [
                            _c("p", { staticClass: "text-align-left" }, [
                              _c("b", [_vm._v("French Version")])
                            ])
                          ]),
                          _c(VFlex, { attrs: { xs2: "" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "text-align-right",
                                attrs: { xs4: "" }
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { flat: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.edit(
                                          _vm.selectedAnnouncement
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("EDIT")]
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        [
                          _c(VFlex, { attrs: { xs12: "" } }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.getImageUrl(
                                    _vm.selectedAnnouncement,
                                    "fr"
                                  ) ||
                                  require("@/assets/announcement-img-default.png"),
                                alt: _vm.getDescription(
                                  _vm.selectedAnnouncement,
                                  "fr"
                                ),
                                width: "560px",
                                height: "240px"
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "15px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { persistent: "", width: "600" },
          model: {
            value: _vm.sitesDialog,
            callback: function($$v) {
              _vm.sitesDialog = $$v
            },
            expression: "sitesDialog"
          }
        },
        [
          _vm.selectedAnnouncement
            ? _c(
                VCard,
                [
                  _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
                    _vm._v(_vm._s(_vm.selectedAnnouncement.name))
                  ]),
                  _c(
                    "div",
                    { staticClass: "v-card-content" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(VFlex, { attrs: { xs12: "" } }, [
                            _c("b", [_vm._v("Sites")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        VLayout,
                        { staticClass: "mt-2" },
                        [
                          _c(
                            VFlex,
                            {
                              staticStyle: {
                                height: "200px",
                                overflow: "scroll"
                              },
                              attrs: { xs12: "" }
                            },
                            _vm._l(
                              _vm.getSites(_vm.selectedAnnouncement),
                              function(resource, rIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: rIndex,
                                    staticStyle: { height: "25px" }
                                  },
                                  [
                                    _c(VLayout, [
                                      _vm._v(_vm._s(resource.name))
                                    ])
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticStyle: { padding: "15px" } },
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function($event) {
                              return _vm.edit(_vm.selectedAnnouncement)
                            }
                          }
                        },
                        [_vm._v("Edit")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }